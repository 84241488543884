.main-image {
    width: 40%;
  }
  
  .home-hero {
    min-height: fit-content;
  }
  
  .message-box {
    border-radius: 10px;
    height: 8vw;
  }
  
  
  .send-btn {
    background-color: #09658E;
    color: #fff;
    border: none;
  }
  .send-btn:hover {
    background-color: #1b7399;
    color: #ffffff;
    border: none;
  }
  .send-btn:disabled {
    background-color: #979eee;
    color: #fff;
    border: none;
  }
  
  .down-btn
  {
    transition: all 0.5s;
  }

  
  
  .down-btn:hover{
    transition: all 0.5s;
    
    transform: translateY(5px);
   
  }

  .stat-images
  {
    width: 70%;
    object-fit: cover;
    height: auto;
    border-radius:10px;
  }


  .province-images{
   
    
    object-fit:cover ;
    border-radius:20px ;
    width:100%;
  }


.map
  {
    width: 50%;
    object-fit: cover;
    height: auto;
    border-radius:10px;
  }
  
  button , .btn {
    font-size: 0.95em;
  }
  
  .research-area-card
  {
    width: 25%;
    
  }
  
  
  @media only screen and (max-width: 768px) {
    .research-area-card
  {
    width: 100%;
  }

  .stat-images
  {
    width: 100%;
    object-fit: cover;
    height: auto;
  }

  
  
    .main-image {
      width: 80%;
    }
  
    .home-hero {
      height: fit-content;
    }
  
    .message-box {
      border-radius: 10px;
      height: 30vw;
    }
  }
  
  